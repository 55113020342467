//o365.vue.components.renderers.js
import { toRefs, computed, useSlots, inject } from 'vue';

 

const OCheck = {
    name: "OCheck",
    props: ['modelValue', 'column', 'editModeOn', 'disabled'],
    render() {
        const editable = typeof this.column.editable === 'function' ? this.column.editable(this.modelValue) : this.column.editable;
        return <input disabled={!editable || this.disabled} type="checkbox" v-model={this.modelValue[this.column.field]} />;
    }
}

const OLink = {
    name: 'OLink',
    props: ['modelValue', 'column', 'editModeOn', 'instance', 'cellRendererParams', 'href', 'target'],
    setup(props, context) {

        const { modelValue, column } = toRefs(props);
        const slots = useSlots();
        const cellRendererParams = computed(() => props.cellRendererParams ?? column.value.cellRendererParams);

        if (column.value && column.value.exportMetaData) {
            if (cellRendererParams?.value.constructor === String) {
                column.value.exportMetaData.linkUrl = cellRendererParams.value;
            } else if (typeof cellRendererParams?.value?.href === 'string') {
                column.value.exportMetaData.linkUrl = cellRendererParams.value.href;
            }
        }

        const displayValue = computed(() => cellRendererParams?.value?.display?.call(modelValue.value) ?? slots.default?.() ?? modelValue.value[column.value.field]);
        const displayClass = computed(() => cellRendererParams?.value?.class ?? 'text-decoration-none');
        const target = computed(() => cellRendererParams?.value?.target === null ? null : (cellRendererParams?.value?.target ?? '_blank'));
        const to = computed(() => { 
            if (typeof cellRendererParams?.value?.to == 'string') { 
                return replaceFromHref(cellRendererParams?.value?.to);
            }
            return cellRendererParams?.value?.to;
        });

        if (cellRendererParams?.value.href && to.value) {
            console.warn("Both href and to specified for OLink cellRendererParams");
        }

        function replaceFromHref(href) {
            return href.replace(/\{{(.*?)\}}/g, (field) => encodeURI(modelValue.value[field.substring(2, field.length - 2)]));
        }

        function buildHref() {

            if (cellRendererParams?.value.constructor === String) {
                return replaceFromHref(cellRendererParams?.value);
            } else if (typeof cellRendererParams?.value === 'function') {
                return cellRendererParams?.value(modelValue.value);
            } else {
                if (typeof cellRendererParams?.value.href === 'function') {
                    return cellRendererParams?.value.href(modelValue.value);
                } else {
                    return replaceFromHref(cellRendererParams?.value.href);
                }
                // Parse from object
                // const appId = column.value.cellRendererParams.appId;
                // const queryParameters = column.value.cellRendererParams.queryParameters ?? [];

                // let validUrl = '';
                // validUrl = `/${appId}`;

                // if (queryParameters.length !== 0) {
                //     validUrl += '?';
                //     queryParameters.forEach((parameterObj, index) => {
                //         let value = '';
                //         switch (typeof parameterObj.value) {
                //             case 'string':
                //                 value = encodeURI(modelValue.value[parameterObj.value]);
                //                 break;
                //             case 'function':
                //                 value = encodeURI(parameterObj.value(modelValue.value));
                //                 break;
                //             default:
                //                 console.error(`OLink: Invalid ${parameterObj.parameter} value definition`);
                //                 return;
                //         }

                //         if (index < queryParameters.length - 1) { validUrl += '&'; }
                //         validUrl += `${parameterObj.parameter}=${value}`;
                //     });
                // }

                // return validUrl;
            }
        }

        function onClick(e) {
            if (cellRendererParams?.value?.preventReload) {
                e.preventDefault();
                history.pushState({}, '', e.currentTarget.href);
            }
            if (cellRendererParams?.value?.onClick) {
                cellRendererParams?.value?.onClick(modelValue);
            }
        }
        return { buildHref, displayValue, displayClass, target, onClick, to }
    },
    render() {
        return this.to ? 
            <RouterLink to={this.to}
                class={this.displayClass}
                onClick={this.onClick}>
                {{
                    default: () => this.displayValue
                }}
            </RouterLink> 
            
            :
            
            <a href={this.buildHref()}
                class={this.displayClass}
                target={this.target}
                // rel="noopener noreferrer"
                onClick={this.onClick}>
                {{
                    default: () => this.displayValue
                }}
            </a>
    }
}


function OBodyWrapper(props, { slots }) {
    const Container = props.is ?? OColContainer;
    return props.disabled
        ? slots.default()
        : <Container class={props.class}>
            {slots.default()}
        </Container>;
}
OBodyWrapper.props = { disabled: Boolean, class: null, is: String };

export { OCheck, OLink, OBodyWrapper };
